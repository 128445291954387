import { Button, Card, Modal } from "react-bootstrap";

const title = "Specialised Cleaning Services";


const CleaningServicesModal = (props) => {

  return (
    <Modal
      {...props}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="h1">
          {title}(Pipes/Tubes/Pressure Vessels)
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Experience unparalleled cleanliness and efficiency with our specialized cleaning services tailored for pipes, tubes, and pressure vessels. From meticulous chemical cleaning to high-pressure water jetting and precise pickling and passivation techniques, we ensure optimal performance and longevity for your critical equipment.
        </p>
        <Card className="px-4">
          <ul>
            <li>Chemical cleaning</li>
            <li>High pressure water jetting</li>
            <li>Pickling and passivation</li>
          </ul>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.onHide(title)}>Get quote</Button>
      </Modal.Footer>
    </Modal >
  );
}

export default CleaningServicesModal;
