import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

const Mailto = ({ email, subject = "", message = "", phone = "", validated = false, children }) => {
  let params = "?";
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  const body = `${message} \n\nPlease find the contact details below:\nPhone:  ${phone} \nEmail: ${email}`;
  params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

  let className = validated ? "btn btn-primary" : "btn btn-primary disabled"
  return <a href={`mailto:info@tensorme.com${params}`} className={className}>
    {children}
  </a>;
};

const AppContact = (props) => {
  const [state, setState] = useState({
    email: "",
    fullName: "",
    phone: "",
    subject: props.subject ?? "",
    message: "",
  });

  const [validationState, setValidationState] = useState({
    email: false,
    fullName: false,
    phone: false,
    subject: false,
    message: false,
  });


  const [validated, setValidated] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevProps) => ({
      ...prevProps,
      [name]: value
    }));

    const form = event.currentTarget;
    const isFormValid = form.checkValidity()
    setValidationState((prevProps) => ({
      ...prevProps,
      [name]: isFormValid,
    }));
  };

  useEffect(() => {
    const subject = props.subject
    setState((prevProps) => ({
      ...prevProps,
      "subject": subject
    }));

    setValidationState((prevProps) => ({
      ...prevProps,
      "subject": subject.length !== 0
    }));

  }, [props.subject]);

  useEffect(() => {
    const isValid = Object.values(validationState).every(value => value);
    setValidated(isValid);
  }, [validationState]);

  return (
    <section ref={props.contactRef} id="contact" className="block contact-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Contact us</h2>
          <div className="subtitle">get connected with us</div>
        </div>
        <Form noValidate validated={validated} className="contact-form">
          <Row>
            <Col sm={4}>
              <Form.Control
                name="fullName"
                value={state.fullName}
                type="text"
                autoComplete="on"
                onChange={handleInputChange}
                placeholder="Enter your full name"
                required
              />
            </Col>
            <Col sm={4}>
              <Form.Control
                name="email"
                value={state.email}
                type="email"
                autoComplete="on"
                onChange={handleInputChange}
                placeholder="Enter your email address"
                required
              />
            </Col>
            <Col sm={4}>
              <Form.Control name="phone" autoComplete="on" value={state.phone} type="tel" onChange={handleInputChange} placeholder="Enter your contact number" required />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Control name="subject" value={state.subject} type="text" onChange={handleInputChange} placeholder="Subject" />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Control name="message" value={state.message} as="textarea" onChange={handleInputChange} placeholder="Enter your message" required />
            </Col>
          </Row>
          <div className="btn-holder">
            <Mailto email={state.email} subject={state.subject} message={state.message} phone={state.phone} validated={validated}>
              Send Email
            </Mailto>
          </div>
        </Form>
      </Container>
      <div className="google-map">
        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.5259661541245!2d55.3387095!3d25.252886399999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6dc36f0b1c385019%3A0x54e0396cd054bd84!2sTensor%20Engineering%20Services!5e0!3m2!1sen!2sin!4v1709906722103!5m2!1sen!2sin"></iframe>
      </div>
      <Container fluid>
        <div className="contact-info">
          <Row>
            <Col sm={4} key="email">
              <Card className="shadow">
                <Container className="p-3 m-auto">
                  <a href="mailto:info@tensorme.com">
                    <i className="fas fa-envelope"></i>
                    info@tensorme.com
                  </a>
                </Container>
              </Card>
            </Col>
            <Col sm={4} key="phone">
              <Card className="shadow">
                <Container className="p-3 m-auto d-block">
                  <a href="tel:+97146657005">
                    <i className="fas fa-phone"></i>
                    +971 4 665 7005
                  </a>
                </Container>
              </Card>
            </Col>
            <Col sm={4} key="location">
              <Card className="shadow">
                <Container className="p-3 m-auto">
                  <a href="https://maps.app.goo.gl/SUUqd73tbKuRffKR7">
                    <i className="fas fa-map-marker-alt"></i>
                    Dubai, United Arab Emirates
                  </a>
                </Container>
              </Card>
            </Col>
          </Row>
        </div>
      </Container >
    </section >
  );
}

export default AppContact;
