
const userScroll = () => {
  window.addEventListener('scroll', () => {
    const header = document.querySelector('#header');
    if (window.scrollY > 50) {
      header.classList.add('bg-dark');
      header.classList.add('header-sticky');
    } else {
      header.classList.remove('bg-dark');
      header.classList.remove('header-sticky');
    }
  })
}

export default userScroll;
