import Carousel from 'react-bootstrap/Carousel';

var heroData = [
  {
    id: 1,
    image: require('../assets/images/hero/hero-1.jpeg'),
    title: 'Specialized Engineering Solutions',
    description: 'Tailored solutions for industrial, marine, and oil & gas sectors, optimizing operational efficiency and ensuring regulatory compliance.',
    link: '#contact'
  },
  {
    id: 2,
    image: require('../assets/images/hero/hero-2.jpeg'),
    title: 'Equipment Procurement Expertise',
    description: 'Specialized sourcing, customization, and support for top-quality oil & gas, marine, and testing equipment.',
    link: '#contact'
  },
  {
    id: 3,
    image: require('../assets/images/hero/hero-3.jpeg'),
    title: 'Rental Services',
    description: 'High-pressure testing, hydraulic power packs, torque wrenches, hot oil flushing, and more for industrial needs.',
    link: '#contact'
  }
]

function AppHero() {
  return (
    <section id="home" className="hero-block">
      <Carousel>
        {
          heroData.map(hero => {
            return (
              <Carousel.Item key={hero.id}>
                <img
                  className="d-block w-100"
                  src={hero.image}
                  alt={"slide " + hero.id}
                />
                <div className='d-relative h-100'>
                  <Carousel.Caption>
                    <h2>{hero.title}</h2>
                    <p>{hero.description}</p>
                    <a className="btn btn-primary" href={hero.link}>Contact us</a>
                  </Carousel.Caption>
                </div>
              </Carousel.Item>
            );
          })
        }
      </Carousel>
    </section>
  );
}

export default AppHero;
