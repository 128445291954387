import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import ProgressBar from 'react-bootstrap/ProgressBar';

import img1 from '../assets/images/about/about.jpg';

function AppAbout() {

  return (
    <section id="about" className="block about-block">
      <Container fluid>
        <div className="title-holder">
          <h2>About Us</h2>
          <div className="subtitle">learn more about us</div>
        </div>
        <Row>
          <Col sm={6}>
            <Image src={img1} />
          </Col>
          <Col sm={6}>
            <p>Tensor Engineering Services LLC, a subsidiary of Tensorloop Pty Ltd headquartered in Australia, is a leading provider of specialized engineering services catering to the industrial, marine, and oil & gas sectors. With a wealth of experience and a deep understanding of the unique challenges inherent in these industries, we offer bespoke service support tailored to the specific requirements of our clients.</p>

            <p>Our team comprises highly skilled engineers and technicians who are equipped with state-of-the-art tools and technologies to deliver exemplary solutions across various segments of the industry. From upstream exploration and production to downstream refining and distribution, we offer a comprehensive range of services aimed at enhancing operational efficiency, optimizing asset performance, and ensuring regulatory compliance.</p>

            {/* <p>At Tensor Engineering, safety and reliability are paramount. We adhere rigorously to the highest industry standards and best practices to ensure the well-being of our personnel and the environment. Our commitment to excellence transcends mere compliance – we strive to exceed expectations in every project we undertake.</p> */}
            {/**/}
            {/* <p>Whether it entails routine maintenance, emergency repairs, or innovative engineering solutions, our team is dedicated to delivering results that create value for our clients. With an unwavering focus on innovation, efficiency, and customer satisfaction, Tensor is your trusted partner for all your service support needs in the dynamic oil and gas industry.</p> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AppAbout;
