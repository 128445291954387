import { Button, Card, Modal } from "react-bootstrap";

const title = "Control Valves & Actuator Services"

const ValvesServicesModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="h1">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Drawing on extensive expertise in valve actuation, our highly experienced team at Tensor delivers efficient, timely, and comprehensive shop and field services. Covering the entire spectrum from installation to maintenance and repair, our offerings provide a complete suite of services aimed at maximizing the value derived from your valves. With cradle-to-grave support, we ensure the longevity and optimal performance of all your valves.
        </p>
        <Card className="px-4">
          <ul>
            <li>Pre commissioning activities</li>
            <li>Hydro testing services</li>
            <li>De-contamination services</li>
            <li>Helium leak testing</li>
            <li>High pressure testing</li>
          </ul>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.onHide(title)}>Get quote</Button>
      </Modal.Footer>
    </Modal >
  );
}

export default ValvesServicesModal;
