import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import AppHeader from './components/Header';
import AppHero from './components/Hero';
import AppAbout from './components/About';
import AppServices from './components/Services';
import AppContact from './components/Contact';
import AppFooter from './components/Footer';
import { useRef, useState } from 'react';


function App() {
  const [contactSubject, setContactSubject] = useState("");
  const contactRef = useRef(null);
  return (
    <>
      <div className="App">
        <header id='header'>
          <AppHeader
            navigateToContacts={(subject) => {
              if (subject === undefined || subject.length === 0) {
                return;
              }
              setContactSubject(`Enquiry on ${subject}`)
              contactRef.current?.scrollIntoView({ behaviour: "smooth" })
            }}
          />
        </header>
        <main>
          <AppHero />
          <AppAbout />
          <AppServices />
          <AppContact contactRef={contactRef} subject={contactSubject} />
        </main>
        <footer id="footer">
          <AppFooter />
        </footer>
      </div>
    </>
  );
}

export default App;
