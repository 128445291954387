import { Button, Card, Modal } from "react-bootstrap";

const title = "Pipeline Services"

const PipelineServicesModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="h1">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          We provide a comprehensive maintenance plan designed to extend the longevity of your critical systems and equipment. Our flush plans encompass a thorough assessment of reservoirs, screens, heat exchangers, coolers, turbines, and all related system components. Our services include comprehensive solutions for contaminant removal, moisture level adjustment, varnish elimination, and meticulous degreasing and pickling of equipment.
        </p>
        <Card className="px-4">
          <ul>
            <li>Pre commissioning activities</li>
            <li>Hydro testing services</li>
            <li>De-contamination services</li>
            <li>Helium leak testing</li>
            <li>High pressure testing</li>
          </ul>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.onHide(title)}>Get quote</Button>
      </Modal.Footer>
    </Modal >
  );
}

export default PipelineServicesModal;
