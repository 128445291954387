import { Button, Card, Modal } from "react-bootstrap";

const title = "Electrical & Instrumentation Services";

const ElectricalServicesModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="h1">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          At Tensor, we specialize in delivering engineering services encompassing Instrumentation, Electrical, and Automation across diverse process domains. Our approach centers on prioritizing customer needs, maintaining exceptional quality standards, ensuring timely project completion, and optimizing costs. Our team of skilled engineers consistently delivers solutions that meet and exceed customer expectations, ensuring prompt and reliable service every time.
        </p>
        <Card className="px-4">
          <ul>
            <li>Instrumentation testing services</li>
            <li>Control system trouble shooting</li>
            <li>Onsite support</li>
            <li>Building automation installation</li>
          </ul>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.onHide(title)}>Get quote</Button>
      </Modal.Footer>
    </Modal >
  );
}

export default ElectricalServicesModal;
