import { useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import CleaningServicesModal from './services/CleaningServicesModal';
import ElectricalServicesModal from './services/ElectricalServicesModal';
import HydraulicServicesModal from './services/HydraulicServicesModal';
import PipelineServicesModal from './services/PipelineServicesModal';
import ValvesServicesModal from './services/ValvesServicesModal';

const AppHeader = (props) => {

  const logo = require('../assets/images/logo/logo.png');

  const [pipelineShow, setPipelineShow] = useState(false);
  const [clearningShow, setCleaningShow] = useState(false);
  const [hydraulicShow, setHydraulicShow] = useState(false);
  const [electricalShow, setElectricalShow] = useState(false);
  const [valvesShow, setValvesShow] = useState(false);

  return (
    <>
      <Navbar bg="dark" expand="lg" variant="dark" collapseOnSelect>
        <Container>
          <Navbar.Brand href="#home">
            <img src={logo}
              height="50"
              width="61"
              alt="Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#about">About</Nav.Link>
              <NavDropdown align="end" title="Services" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => setPipelineShow(true)}>Pipeline Services</NavDropdown.Item>
                <NavDropdown.Item onClick={() => setCleaningShow(true)}>Specialised Cleaning Services</NavDropdown.Item>
                <NavDropdown.Item onClick={() => setHydraulicShow(true)}>Hydraulic Engineering Services</NavDropdown.Item>
                <NavDropdown.Item onClick={() => setElectricalShow(true)}>Electrical & Instrumentation Services</NavDropdown.Item>
                <NavDropdown.Item onClick={() => setValvesShow(true)}>Control Valves & Actuator Services</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="#contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <PipelineServicesModal
        show={pipelineShow}
        onHide={(title) => {
          setPipelineShow(false)
          props.navigateToContacts(title)
        }}
      />
      <CleaningServicesModal
        show={clearningShow}
        onHide={(title) => {
          setCleaningShow(false)
          props.navigateToContacts(title)
        }}
      />
      <HydraulicServicesModal
        show={hydraulicShow}
        onHide={(title) => {
          setHydraulicShow(false)
          props.navigateToContacts(title)
        }}
      />
      <ElectricalServicesModal
        show={electricalShow}
        onHide={(title) => {
          setElectricalShow(false)
          props.navigateToContacts(title)
        }}
      />
      <ValvesServicesModal
        show={valvesShow}
        onHide={(title) => {
          setValvesShow(false)
          props.navigateToContacts(title)
        }}
      />
    </>
  );
}

export default AppHeader;
