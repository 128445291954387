import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const servicesData = [
  {
    id: 1,
    icon: 'fas fa-wrench',
    title: 'Mechanical Services',
    description: 'We provide all-inclusive pipeline services, including testing, decontamination, specialized cleaning, and hydraulic engineering, ensuring optimal performance and extended lifespan of vital systems and equipment.'
  },
  {
    id: 2,
    icon: 'fas fa-plug',
    title: 'Electrical & Instrumentation Services',
    description: 'We provide instrumentation, electrical, and automation services, emphasizing quality, timely delivery, and cost-effectiveness, including supply, installation, testing, calibration, and manpower supply for industrial projects.'
  },
  {
    id: 3,
    icon: 'fas fa-hourglass',
    title: 'Control Valves & Actuator Services',
    description: 'We specialize in preventive valve maintenance, critical valve upkeep, troubleshooting, repair, and actuator installation, ensuring efficient operation and extending equipment lifespan.'
  }
]

function AppServices() {
  return (
    <section id="services" className="services-block">
      <Container className="block container-box">
        <Container fluid>
          <div className="title-holder">
            <h2>Our services</h2>
            <div className="subtitle">services we provide</div>
          </div>
          <Row>
            {
              servicesData.map(services => {
                return (
                  <Col sm={4} className='holder' key={services.id}>
                    <Container>
                      <Card className="shadow">
                        <div className="icon">
                          <i className={services.icon}></i>
                        </div>
                        <h3>{services.title}</h3>
                        <p>{services.description}</p>
                      </Card>
                    </Container>
                  </Col>
                );
              })
            }
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default AppServices;
