import { Button, Card, Modal } from "react-bootstrap";

const title = "Hydraulic Engineering Services";

const HydraulicServicesModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="h1">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Ensure the peak performance and longevity of your hydraulic equipment with our comprehensive servicing and maintenance solutions. Our expert team delivers meticulous on-site maintenance to guarantee uninterrupted functionality and optimal condition for all types of hydraulic equipment.
        </p>
        <Card className="px-4">
          <ul>
            <li>Hydraulic equipment installation and testing</li>
            <li>Hot oil flushing</li>
            <li>Hydraulic system trouble shooting & repairs</li>
            <li>Hydraulic oil condition monitoring</li>
          </ul>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.onHide(title)}>Get quote</Button>
      </Modal.Footer>
    </Modal >
  );
}

export default HydraulicServicesModal;
